import Animate from 'components/atoms/animate'
import Button from 'components/atoms/button'
import Select from 'components/atoms/select'
import Textarea from 'components/atoms/textarea'
import TextField from 'components/atoms/textField'
import Container from 'components/container'
import { Formik } from 'formik'
import Img from 'gatsby-image'
import { useResourceMediaData } from 'hooks/useResourceMediaData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import * as Yup from 'yup'

const StyledWholesaleForm = styled.div`
  ${({ theme }): CSSProp => css`
    background: #e3d5c4;
    .row {
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        display: flex;
      }
    }
    .image {
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        width: 50%;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        width: 33%;
      }
    }
    .gatsby-image {
      position: relative;
      height: 100%;
      margin: 0 -2rem;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        margin-right: 0;
      }
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin-left: -4rem;
      }
      @media only screen and (min-width: 1660px) {
        margin-left: calc((-100vw + 1660px - 80px) / 2);
      }
    }
    .gatsby-image-wrapper {
      height: 100%;
    }
    .form {
      flex: 1;
      padding: 6rem 0 6rem 0rem;
      max-width: 1150px;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        padding: 4rem 0 4rem 2rem;
      }
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        padding: 6rem 0 6rem 4rem;
      }
      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        padding: 12rem 0 12rem 9rem;
      }
    }
    h3 {
      font-size: 18px;
      font-family: ${theme.font.fontFamilyAlt};
      margin-bottom: 2rem;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        text-align: center;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-bottom: 4rem;
      }
    }
    .form-fields {
      display: grid;
      grid-gap: 1.7rem 2rem;
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        grid-template-columns: 1fr 1fr;
      }
    }
    .field {
      input,
      .select {
        width: 100%;
      }
    }
    label {
      display: block;
      font-weight: ${theme.font.fontWeightLight};
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        font-size: 14px;
      }
    }
    .button {
      display: block;
      margin-left: auto;
      margin-top: 1.7rem;
    }
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
    }
  `}
`

type WholesaleFormProps = {
  region: string
}

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  phone: Yup.string().required('Required'),
})

const WholesaleForm = ({ region }: WholesaleFormProps): JSX.Element => {
  const { image: imageObj, imageAlt } = useResourceMediaData(
    'wholesale.formImage'
  )
  return (
    <Animate>
      <StyledWholesaleForm>
        <Container>
          <div className="row">
            <div className="image">
              <div className="gatsby-image">
                <Img fluid={imageObj} alt={imageAlt} />
              </div>
            </div>

            <Formik
              initialValues={{ email: '', stage: '' }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2))
                  setSubmitting(false)
                }, 400)
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form className="form" onSubmit={handleSubmit}>
                  <h3>
                    {useResourceTextData(
                      'wholesale.formTitle',
                      'Online Wholesale Enquiry'
                    )}
                  </h3>

                  <div className="form-fields">
                    <div className="field">
                      <TextField
                        type="text"
                        name="name"
                        placeholder={
                          useResourceTextData(
                            'wholesale.formFieldName',
                            'Name'
                          ) + '*'
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        className={errors.name && 'error'}
                      />
                    </div>
                    <div className="field">
                      <TextField
                        type="text"
                        name="company"
                        placeholder={useResourceTextData(
                          'wholesale.formFieldCompany',
                          'Company'
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                      />
                    </div>
                    <div className="field">
                      <TextField
                        type="text"
                        name="email"
                        placeholder={
                          useResourceTextData(
                            'wholesale.formFieldEmail',
                            'Email'
                          ) + '*'
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={errors.email && 'error'}
                      />
                    </div>
                    <div className="field">
                      <TextField
                        type="text"
                        name="phone"
                        placeholder={
                          useResourceTextData(
                            'wholesale.formFieldPhone',
                            'Phone'
                          ) + '*'
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        className={errors.phone && 'error'}
                      />
                    </div>
                    <div className="field">
                      <label>
                        {useResourceTextData(
                          'wholesale.formFieldStage',
                          'What stage are you at your coffee journey?'
                        )}
                      </label>
                      <Select
                        name="stage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.stage}
                      >
                        <option value="" disabled>
                          {useResourceTextData(
                            'wholesale.formFieldPleaseSelect',
                            'Please select'
                          )}
                        </option>
                        <option value="1">Option 1</option>
                        <option value="2">Option 2</option>
                        <option value="3">Option 3</option>
                      </Select>
                    </div>
                    <div className="field">
                      <label>
                        {useResourceTextData(
                          'wholesale.formFieldNotes',
                          'Any notes you’d like to include?'
                        )}
                      </label>
                      <Textarea
                        name="notes"
                        placeholder={useResourceTextData(
                          'wholesale.formFieldTypeNotesHere',
                          'Type notes here'
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notes}
                      />
                    </div>
                  </div>
                  <Button type="submit" alternate disabled={isSubmitting}>
                    {useResourceTextData(
                      'wholesale.formFieldButtonText',
                      'Send my enquiry'
                    )}
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </Container>
      </StyledWholesaleForm>
    </Animate>
  )
}

export default WholesaleForm
