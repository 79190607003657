import Animate from 'components/atoms/animate'
import Link from 'components/atoms/link'
import Select from 'components/atoms/select'
import Subhead from 'components/atoms/subhead'
import Container from 'components/container'
import Layout from 'components/layout'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import Seo from 'components/molecules/seo'
import WholesaleForm from 'components/organisms/wholesaleForm'
import { graphql, navigate, PageProps } from 'gatsby'
import { regions } from 'helpers/locale'
import { useRegionalTextData } from 'hooks/useRegionalTextData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { ContentfulWholesaleContactConnection, Site } from 'types/graphql-types'

const WholesaleContacts = styled.div`
  ${({ theme }): CSSProp => css`
    display: flex;
    margin-top: 7rem;
    font-weight: ${theme.font.fontWeightLight};
    @media only screen and ${theme.breakpoints.toLargeScreen} {
      font-size: 15px;
    }
    @media only screen and ${theme.breakpoints.toMediumScreen} {
      display: block;
    }
    @media only screen and ${theme.breakpoints.toMediumScreen} {
      margin-top: 4rem;
    }
    .copy {
      flex: 1;
      margin-bottom: 5rem;
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-bottom: 10rem;
      }
      h2 {
        line-height: 1.4;
        font-size: 38px;
        font-family: ${theme.font.fontFamilyAlt};
        font-weight: ${theme.font.fontWeightBold};
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          font-size: 28px;
        }
        @media only screen and ${theme.breakpoints.toMediumScreen} {
          font-size: 22px;
          font-weight: normal;
        }
      }
      h2 {
        margin-bottom: 5rem;
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          margin-bottom: 3rem;
        }
      }
      select {
        min-width: 26rem;
      }
    }
    .inner {
      max-width: 56rem;
      padding-right: 4rem;
      @media only screen and ${theme.breakpoints.toLargeScreen} {
        max-width: 38rem;
      }
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        max-width: none;
      }
    }
    .copy-text {
      margin-bottom: 6rem;
    }
    .regions-wrap {
      flex: 1;
      margin-top: 3rem;
      @media only screen and ${theme.breakpoints.toLargeScreen} {
        margin-top: 1rem;
      }
    }
    .regions {
      columns: 2;
      margin-bottom: 5rem;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        columns: 1;
        margin-bottom: 0rem;
      }
    }
    .region {
      margin-bottom: 5rem;
      break-inside: avoid-column;
    }
    .region-contact {
      margin-bottom: 2rem;
      a[href*='tel'] {
        text-decoration: none;
      }
    }
  `}
`

type WholeSaleTypes = PageProps & {
  data: {
    site: Site
    allContentfulWholesaleContact: ContentfulWholesaleContactConnection
  }
}

const WholesaleTeam = ({ data, pageContext }: WholeSaleTypes): JSX.Element => {
  return (
    <Layout>
      <Seo title="Wholesale team" />
      <Container>
        <Breadcrumbs margin>
          <Link to="/wholesale">
            {useResourceTextData('wholesale.title', 'Wholesale')}
          </Link>{' '}
          <span>-</span>
          {pageContext.region.name}
        </Breadcrumbs>

        <Animate>
          <WholesaleContacts>
            <div className="copy">
              <div className="inner">
                <h2>
                  {useResourceTextData(
                    'wholesaleTeam.title',
                    'Wholesale coffee for'
                  )}
                  <br /> {pageContext.region.name}
                </h2>
                <div className="copy-text">
                  {useRegionalTextData('wholesale.team.intro')}
                </div>

                <Subhead>
                  {useResourceTextData(
                    'wholesaleTeam.inAnotherRegion',
                    'In another region?'
                  )}
                </Subhead>
                <Select
                  outline
                  value={pageContext.regionId}
                  onChange={e => {
                    navigate(`/wholesale/${regions[e.target.value].slug}`)
                  }}
                >
                  {Object.keys(regions).map(id => (
                    <option key={id} value={id}>
                      {regions[id].name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="regions-wrap">
              <div className="regions">
                {data.allContentfulWholesaleContact?.nodes.map((contact, k) => (
                  <div className="region" key={`region-contact-${k}`}>
                    <Subhead>{contact.region}</Subhead>
                    <div className="region-contact">
                      {contact.phone && (
                        <>
                          <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                          <br />
                        </>
                      )}
                      {contact.email && (
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </WholesaleContacts>
        </Animate>
      </Container>

      <WholesaleForm />
    </Layout>
  )
}

export default WholesaleTeam

export const pageQuery = graphql`
  query WholeSaleTeamQuery($regionId: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulWholesaleContact(
      sort: { fields: region }
      filter: { siteRegionId: { eq: $regionId }, node_locale: { eq: $locale } }
    ) {
      nodes {
        email
        phone
        region
        node_locale
      }
    }
  }
`
